import styled from '@xstyled/styled-components';
import { categoryIcons } from 'global/data';
import getCategoryId from 'utils/getCategoryId';
import Category from './Category';
import CategorySlider from './CategorySlider';
import CategorySwiper from './CategorySwiper';
import { Container } from '@/UI/Layout/Container';
import { CategoryTitle } from '@/UI/Primitives/Headings';

const CategoriesWrapper = styled.div`
  section:nth-of-type(odd) {
    background-color: #f6f9f9;
  }

  section:nth-of-type(even) {
    background-color: #fbfbfb;
  }
`;

const CategoryContainer = styled.section`
  display: flex;
  padding: 2rem 1rem;
  width: 100vw;

  @media (min-width: 900px) {
    padding: 0 2rem 2rem;
  }
`;

const CategoryInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  padding: 2rem 0.75rem 2rem 1.375rem;
  border-radius: 10px;
  background-color: white;

  @media (min-width: desktop) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 2rem 2rem 1rem 1.375rem;
  }
`;

export default function Categories({ categories }) {
  const getCategory = group => {
    let category = {};

    categoryIcons.forEach(item => {
      if (item.category === group.name) {
        category = { ...group, icon: item.icon };
      } else if (item.categoryLT === group.name) {
        category = { ...group, icon: item.icon };
      }
      else if (item.categoryEN === group.name) {
        category = { ...group, icon: item.icon };
      }
    });


    return category;
  };

  return (
    <CategoriesWrapper>
      {/* Slice to remove "Blog" & "Kinkekaart" */}
      {categories?.slice(0, -3).map(category => {
        const group = getCategory(category);
        const id = getCategoryId(group.url_key);
        return (
          <CategoryContainer key={id}>
            <Container>
              <CategoryInnerContainer>
                <Category category={group} />
                <CategorySlider id={id ? id : category.id} />
                <CategorySwiper id={id ? id : category.id} />
              </CategoryInnerContainer>
            </Container>
          </CategoryContainer>
        );
      })}
    </CategoriesWrapper>
  );
}

