import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useCategoryProducts from 'hooks/products/useCategoryProducts';
import useCategoryProductsHome from 'hooks/products/useCategoryProductsHome';

import styled from '@xstyled/styled-components';
import ProductCard from '@/UI/ProductCard';
import Loader from '@/UI/Loader';
import CarouselArrow from '@/UI/Primitives/CarouselArrow';
import { SliderArrowRight, SliderArrowLeft } from '@/UI/Icons';
import SkeletonSmallCard from '@/UI/Skeletons/SkeletonSmallCard';

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 850px;
  position: relative;
  left: 16;

  @media (max-width: lg_desktop) {
    max-width: 750px;
    padding-right: 2rem;
    margin-right: -2rem;
  }

  @media (max-width: desktop) {
    display: none;
  }

  /* &:after {
    content: '';
    height: 1.5px;
    width: 90%;
    position: absolute;
    left: 2.25rem;
    top: 47%;
    background-color: #e3e9ec;
    z-index: 1;
  }*/
`;

const Arrow = styled.div`
  cursor: pointer;
  position: relative;
  bottom: 1rem;
`;

const SlideWrapper = styled.div`
  position: relative;

  /* &:after {
    content: '';
    height: 100%;
    width: 1.5px;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    background-color: #e3e9ec;
    z-index: 2;
  } */
`;

/**
 * CategorySlider
 *
 * Desktop carousel for categories on main page
 *
 * @prop {ReactNode | ReactNodeArray} id - category ID
 */

export default function CategorySlider({ id }) {
  const swiperRef = useRef(null);

  const [arrows, setArrows] = useState({ left: false, right: true });
  
  const { getCategoryProducts, loading, error } = useCategoryProducts(id);

  const { getCategoryProductsHome, isLoading, isError } = useCategoryProductsHome(id);

  
  const [products, setProducts] =useState([])
  useEffect(async () => {
    const prodsHomePromise = getCategoryProductsHome()
    const prodsPromise =  getCategoryProducts()

    const [prodsHome, prods] = await Promise.all([prodsHomePromise, prodsPromise])


    const productsArr = Object.assign([], prods.data?.products?.items);
    const productsHomeArr = Object.assign([], prodsHome.data?.products?.items);
    const ids = new Set(productsHomeArr.map(d => d?.sku));
    const mergedProducts = [...productsHomeArr, ...productsArr.filter(d => !ids.has(d?.sku))];
    setProducts(mergedProducts)
  },[])

  const numArray = [...Array(16)];

  if (error) return null;

  return (
    <SliderContainer>
      <>
        {products?.length > 8 && arrows.left && (
          <Arrow>
            <CarouselArrow
              onClick={() => swiperRef.current?.swiper.slidePrev()}
            >
              <SliderArrowLeft strokeWidth={3} />
            </CarouselArrow>
          </Arrow>
        )}
        <Swiper
          ref={swiperRef}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              slidesPerColumn: 2
            },
            1280: {
              slidesPerView: 4,
              slidesPerColumn: 2
            }
          }}
          slidesPerColumnFill="row"
          onFromEdge={() => {
            setArrows({ left: true, right: true });
          }}
          onReachBeginning={() => {
            setArrows({ left: false, right: true });
          }}
          onReachEnd={() => {
            setArrows({ left: true, right: false });
          }}
        >
          {loading ? (
            <>
              {numArray.map((_, i) => {
                return (
                  <SwiperSlide key={i} mt={8}>
                    <SkeletonSmallCard />
                  </SwiperSlide>
                );
              })}
            </>
          ) : (
            <>
              {products?.map((product, i) => {
                return (
                  <SwiperSlide key={i}>
                    <SlideWrapper>
                      <ProductCard
                        key={product.sku}
                        product={product}
                        imageSize={{ width: 1000, height: 1040 }}
                        hideRating
                        w={204}
                        p={{ desktop: '1rem 0' }}
                        border="none"
                        scale={0.7}
                        hoverScale={0.8}
                      />
                    </SlideWrapper>
                  </SwiperSlide>
                );
              })}
            </>
          )}
        </Swiper>
        {products?.length > 8 && arrows.right && (
          <Arrow>
            <CarouselArrow
              onClick={() => swiperRef.current?.swiper.slideNext()}
            >
              <SliderArrowRight strokeWidth={3} />
            </CarouselArrow>
          </Arrow>
        )}
      </>
    </SliderContainer>
  );
}
