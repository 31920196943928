import { useQuery, useLazyQuery } from '@apollo/client';
import { CATEGORY_PRODUCTS } from 'graphql/queries';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { USER_TOKEN } from 'global/constants';


export default function useCategoryProducts(id) {
  const router = useRouter();
  const currentLang = router.locale;

  const cookies = new Cookies();
  const token = cookies.get(USER_TOKEN);

  const [getCategoryProducts, { data, loading, error }] = useLazyQuery(
    CATEGORY_PRODUCTS,
    {
      variables: { id },
      context: {
        headers: { store: currentLang },
        fetchOptions: { method: 'GET' },
        authorization: token ? `Bearer ${token}` : '',

      },
      fetchPolicy: 'cache-and-network'
    }
  );

  //const products = data?.products?.items;

  return { getCategoryProducts, loading, error };
}
