import Link from 'next/link';
import styled, { Box, useTheme } from '@xstyled/styled-components';
import getLinkPath from 'utils/getLinkPath';
import { CategoryTitle } from '@/UI/Primitives/Headings';
import { FlexBox } from '@/UI/Primitives/FlexBox';
import { Chevron } from '@/UI/Icons';

const CategoryBox = styled.div`
  padding-left: 0.5rem;

  @media (min-width: desktop) {
    padding-left: 1.5rem;

    span.arrow {
      display: none;
    }
  }
`;

const Subcategories = styled.ul`
  margin-left: 2.1rem;

  @media (min-width: desktop) {
    margin-left: -1rem;
    margin-top: 1.5rem;
  }

  li {
    line-height: 2.2;

    &:active {
      font-weight: bold;
    }
  }
`;

export default function Category({ category }) {
  const theme = useTheme();

  return (
    <CategoryBox>
      <Link href={getLinkPath(category.url_key)}>
        <a>
          <CategoryTitle
            withIcon
            position={{ desktop: 'relative' }}
            right="1rem"
          >
            <FlexBox
              as="span"
              align="center"
              fontSize="small"
              whiteSpace="nowrap"
            >
              <span role="img" aria-label="Category symbol">
                {category.icon}
              </span>
              {category.name}
            </FlexBox>
            <span className="arrow" role="img" aria-label="Choose category">
              <Chevron fill={theme.colors['teal-300']} />
            </span>
          </CategoryTitle>
        </a>
      </Link>
      <Subcategories>
        {category.children?.map((item, index) => {
          return (
            <li key={index}>
              <Link href={getLinkPath(item.url_key, category.url_key)} passHref>
                <Box as="a" hoverColor="gray-200">
                  {item.name}
                </Box>
              </Link>
            </li>
          );
        })}
      </Subcategories>
    </CategoryBox>
  );
}
