import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from '@xstyled/styled-components';
import useCategoryProducts from 'hooks/products/useCategoryProducts';
import useCategoryProductsHome from 'hooks/products/useCategoryProductsHome';

import ProductCard from '@/UI/ProductCard';
import SkeletonSmallCard from '@/UI/Skeletons/SkeletonSmallCard';
import { useEffect, useState } from 'react';
SwiperCore.use([Pagination]);

const SwiperContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: desktop) {
    display: none;
  }

  .swiper-slide {
    opacity: 0.25;
  }

  .swiper-slide-visible {
    opacity: 1;
  }

  .swiper-slide div {
    @media (max-width: sm_tablet) {
      margin: 0 auto;
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  padding-bottom: 3rem;

  .swiper-pagination-bullet-active {
    background-color: teal-300;
  }

  @media (min-width: desktop) {
    display: none;
  }
`;


export default function CategorySwiper({ id }) {
  const { getCategoryProducts, loading, error } = useCategoryProducts(id);
  const { getCategoryProductsHome, isLoading, isError } = useCategoryProductsHome(id);

  
  const [products, setProducts] =useState([])
  useEffect(async () => {
    const prodsHomePromise = getCategoryProductsHome()
    const prodsPromise =  getCategoryProducts()

    const [prodsHome, prods] = await Promise.all([prodsHomePromise, prodsPromise])

    const productsArr = Object.assign([], prods.data?.products?.items);
    const productsHomeArr = Object.assign([], prodsHome.data?.products?.items);
    const ids = new Set(productsHomeArr.map(d => d.sku));
    const mergedProducts = [...productsHomeArr, ...productsArr.filter(d => !ids.has(d.sku))];

    setProducts(mergedProducts)
  },[])

  const numArray = [...Array(4)];

  if (error) {
    return null;
  }

  return (
    <SwiperContainer>
      <StyledSwiper
        spaceBetween={100}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 100
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 150
          },
          360: {
            slidesPerView: 2,
            spaceBetween: 100
          },
          460: {
            slidesPerView: 3,
            spaceBetween: 200
          }
        }}
        pagination={{ dynamicBullets: true, dynamicMainBullets: 3 }}
        loop
        watchSlidesVisibility
      >
        <>
          {loading ? (
            <>
              {numArray.map((_, i) => {
                return (
                  <SwiperSlide key={i}>
                    <SkeletonSmallCard />
                  </SwiperSlide>
                );
              })}
            </>
          ) : (
            <>
              {products.slice(0, 8).map(product => {
                return (
                  <SwiperSlide key={product.sku}>
                    <ProductCard
                      product={product}
                      imageSize={{ width: 1000, height: 1040 }}
                      w={{ _: 250, lg_tablet: 300 }}
                      hideRating
                      border="none"
                    />
                  </SwiperSlide>
                );
              })}
            </>
          )}
        </>
      </StyledSwiper>
    </SwiperContainer>
  );
}
