import { useQuery, useLazyQuery } from '@apollo/client';
import { CATEGORY_PRODUCTS_HOME } from 'graphql/queries';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { USER_TOKEN } from 'global/constants';

export default function useCategoryProductsHome(id) {
  const router = useRouter();
  const currentLang = router.locale;

  const cookies = new Cookies();
  const token = cookies.get(USER_TOKEN);

  const [getCategoryProductsHome, { data, loading, error }] = useLazyQuery(
    CATEGORY_PRODUCTS_HOME,
    {
      variables: { id },
      context: {
        headers: { store: currentLang },
        fetchOptions: { method: 'GET' },
        authorization: token ? `Bearer ${token}` : '',

      },
      fetchPolicy: 'network-only'
    }
  );


  return { getCategoryProductsHome, loading, error };
}
